<template>
  <div class="partner-custom-form flights-form">
    <flights-form :flightObject="flight" />
  </div>
</template>

<script>
import FlightsForm from "./components/FlightForm.vue"

export default {
  components: {
    FlightsForm
  },
  data() {
    const d = new Date()
    d.setHours(10)
    d.setMinutes(0)
    return {
      flight: {
        date: d
      },
    };
  }
};
</script>
